import { useCallback, useEffect, useState } from 'react'
import { message } from 'antd'

import { useProviderContext } from '../context/ProviderContext'

import TemplatesAPI from "../../../axios/templates/TemplateAPI"
import PhysicianAPI from "../../../axios/physician/PhysicianApi"

import { useCaseContext } from '../context/CaseContext'

export function usePrescriptionTemplates({
    form,
    prescription,
    clearQueuedPrescription,
    saveQueuedPrescription,
    dispenseUnits,
    getDispenseUnitById,
    validateRx,
    setShowPharmacyNotesTextArea,
    setNewPharmacyForRx,
}) {
    const [templatesLoading, setTemplatesLoading] = useState(true)
    const [selectedTemplate, selectTemplate] = useState(null)
    const [templateOwnerType, selectTemplateOwnerType] = useState('admin')
    const [templates, setTemplates] = useState(null)
    const { _case } = useCaseContext()

    const searchTemplates = useCallback(async ({ drugName, strength }) => {
        selectTemplate(null)
        setTemplates([])
        setTemplatesLoading(true)

        try {
            const { _id: categoryId } = _case.category

            const { data: { templates } } = await PhysicianAPI.searchPrescriptionTemplates({
                categoryId,
                drugName,
                strength
            })

            message.success(`${templates.length} template matches found.`)
            setTemplates(templates)
            return templates
        } catch (e) {
            message.error("Error fetching prescription templates")
            console.log(e)
        } finally {
            setTemplatesLoading(false)
        }
    }, [_case?._id])

    function removeStrengthFromQuantity(inputStr) {
        const numericStr = inputStr.replace(/[^\d.]/g, '');
        const numericStrTrimmed = numericStr.trim();
        const numericValue = parseFloat(numericStrTrimmed);
        return numericValue;
    }

    const handleSelectTemplate = ({
        template,
        prescription
    }) => {
        if (template && prescription?._id) {

            selectTemplate(template)
            clearQueuedPrescription(prescription._id)

            const { 
                medication, 
                pharmacyId,
                pharmacyNotes 
            } = template

            const { 
                refills, 
                daysSupply, 
                pillCount,
                dispenseUnitId,
            } = medication

            setNewPharmacyForRx(pharmacyId || _case.pharmacyId)
            setShowPharmacyNotesTextArea(!!pharmacyNotes)

            const dispenseUnit = getDispenseUnitById(dispenseUnitId)
            const refillsToPrescribe = Number(refills?.toString() || prescription.requestedMedication?.refills?.toString() || 0)
            const daysSupplyToPrescribe = Number(daysSupply?.toString() || prescription.requestedMedication?.daysSupply?.toString() || 30)
            const quantityToPrescribe = removeStrengthFromQuantity(pillCount?.toString() || prescription.requestedMedication?.pillCount?.toString() || 0)

            const prescriptionToQueue = {
                ...template,
                ingredients: null,
                ncd: null,
                ...medication,
                name: template.medication.drugName,
                quantityUnits: dispenseUnit?.Name,
                providePharmacyNotes: !!template.pharmacyNotes,
                refills: refillsToPrescribe,
                daysSupply: daysSupplyToPrescribe,
                quantity: quantityToPrescribe,
            }

            form.setFieldsValue(prescriptionToQueue)
            
            saveQueuedPrescription({ 
                ...prescriptionToQueue, 
                _id: prescription._id,
            })

            validateRx()
        }
    }

    const preloadTemplate = async () => {
        if (prescription?.requestedMedication) {  
            const { requestedMedication } = prescription
            
            const templates = await searchTemplates({
                drugName: requestedMedication.drugName,
                strength: requestedMedication.dosage
            })

            if (!templates?.[0]) return

            handleSelectTemplate({ template: templates[0], prescription })
        }
    }

    

    const createTemplate = async ({
        title,
        pharmacyNotes,
        directions,
        teleMedicineCategoryIds,
        ...medication
    }) => {
        try {
            const payload = {
                title,
                pharmacyNotes,
                directions,
                teleMedicineCategoryIds,
                medication
            }

            await TemplatesAPI.create('prescription', payload);
            message.success('Template successfully created')
        } catch (e) {
            message.error('Error creating template')
            throw new Error(e)
        }
    }

    useEffect(() => {
        if (_case?.category && dispenseUnits && templateOwnerType) {
            preloadTemplate()
        }
    }, [dispenseUnits, templateOwnerType, _case])

    return {
        selectTemplate,
        selectedTemplate,
        templatesLoading,
        templates,
        templateOwnerType,
        handleSelectTemplate,
        createTemplate,
        selectTemplateOwnerType,
    };
}
