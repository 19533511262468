import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Select } from 'antd'

import { 
    EditOutlined,
    DeleteOutlined,
    CaretDownOutlined,
    CloseOutlined 
} from '@ant-design/icons'

import { usePrescriptionTemplates } from '../../hooks/usePrescriptionTemplates'

import prescribeMedicine from '../../../../../../../../redux/prescribeMedicine'

import { StyledButton } from '../../../../../Button'
export const Prescription = ({
    background,
    prescription,
    handleSelectPrescription,
    handleRemovePrescription,
    isAssignedProvider,
}) => {
    const dispatch = useDispatch();
    const { prescriptions } = useSelector(state => state.prescribeMedicine)
    
    const { _selectTemplate } = usePrescriptionTemplates()

    const [templatesState, setTemplatesState] = useState({
        options: prescription.templateAlternatives || [],
        mode: 'read',
        searchString: ''
    })

    const mappedTemplateOptions = (templateAlternatives) => {
        return templateAlternatives.map(template => ({
            label: template.title,
            value: template.title,
        }))
    }

    const handleSelectTemplate = (templateTitle) => {
        const selectedTemplate = templatesState.options.find(template => template.title === templateTitle)

       const prescriptionToQueue = _selectTemplate({ 
            template: selectedTemplate, 
            templateAlternatives: prescription.templateAlternatives,
            requestedMedication: prescription.requestedMedication 
        })

        const queue = { ...prescriptions }
        queue[prescriptionToQueue._id] = prescriptionToQueue

        dispatch(prescribeMedicine.actions.setPrescriptionQueue(queue))

        setTemplatesState(prev => ({ ...prev, mode: 'read' }))
    }


    const ReadMode = () => (
        <>
            <strong 
                style={{ 
                    color: '#7BAD7E',
                    fontSize: '1.1rem' 
                }}
            >
                {prescription.drugName || prescription.name} {prescription.strength}
            </strong>
            <div style={{ display: 'inline-block', marginLeft: '3px'}}>
                <StyledButton 
                    size='mini'
                    tooltip='Change Template Medication'
                    Icon={CaretDownOutlined}
                    onClick={() => setTemplatesState({ ...templatesState, mode: 'write' })} 
                />
            </div>
        </>
    )

    const WriteMode = () => (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
            <Select
                style={{ width: '100%' }}
                options={mappedTemplateOptions(templatesState.options)}
                value={templatesState.searchString}
                onSelect={handleSelectTemplate}
                defaultOpen
                placeholder="Search Templates"
            />
            <div style={{ display: 'inline-block', marginLeft: '3px'}}>
                <StyledButton 
                    size='mini'
                    color='red'
                    tooltip='Cancel'
                    Icon={CloseOutlined}
                    onClick={() => setTemplatesState({ ...templatesState, mode: 'read' })} 
                />
            </div>
        </div>
    )

    return (
        <div style={{
            width: '100%',
            padding: '20px',
            marginBottom: '20px',
            background: background || 'rgba(123, 173, 126, .15)',
            color: '#000',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            gap: '5px'
        }}
        >
            <div style={{ width: '100%', display: 'flex' }}>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ width: 'calc(100% - 100px)' }}>
                        { templatesState.mode === 'read' ? <ReadMode /> : <WriteMode /> }
                    </div>
                    {
                        isAssignedProvider && (
                            <div style={{ width: '100px', display: 'flex', justifyContent: 'flex-end', gap: '3px', }}>
                            <StyledButton size='small'
                                          tooltip='Edit Prescription'
                                          Icon={EditOutlined}
                                          onClick={handleSelectPrescription} />
                            <StyledButton size='small' tooltip='Delete Prescription'
                                          Icon={DeleteOutlined}
                                          onClick={handleRemovePrescription}
                                          color="red" />
                        </div>
                        )
                    }
                </div>
            </div>
            <div style={{ display: 'flex', gap: '20px', fontWeight: 'bold' }}>
                <span>Quantity: {prescription.quantity} {prescription.quantityUnits}(s) {prescription.dispense || ''}</span>
                <span>Refills: {prescription.refills}</span>
                <span>Supply: {prescription.daysSupply} days</span>
            </div>
            <br />
            <div><strong>Directions:</strong> {prescription.directions}</div>
            <br />
            <div><strong>Pharmacy Notes:</strong> {prescription.pharmacyNotes || "None"}</div>
            { prescription.DAW && <div style={{ color: 'red'}}><strong>NO SUBSTITUTIONS</strong></div> }
        </div>
    )
}