import React, { useState, useEffect, memo } from 'react'
import { Spin, Typography } from 'antd'

import {
    TemplatesContainer,
    StyledAutoComplete
} from '../styles'

const TemplateSelect = ({
    loading,
    templates,
    prescription,
    handleSelectTemplate,
}) => {

    const [templatesState, setTemplatesState] = useState({
        options: [],
        searchString: ''
    })

    const _handleSelectTemplate = val => {
        const template = templates?.find(template => template.title === val)

        if (!template) return
        
        handleSelectTemplate({
            prescription,
            template
        })

        setTemplatesState(prev => ({
            ...prev,
            searchString: template.title
        }))
    }

    const handleSearch = (searchText) => {
        setTemplatesState({
            searchString: searchText,
            options: templates?.filter(template => template.title.toLowerCase().includes(searchText.toLowerCase()))
        })
    };

    useEffect(() => {
        if (templates?.length) {
            const defaultTemplate = templates[0]

            setTemplatesState(prev => ({
                ...prev,
                options: templates,
                searchString: defaultTemplate.title
            }))

            handleSelectTemplate({
                prescription,
                template: defaultTemplate
            })
        }
    }, [templates])

    return (
        <Spin spinning={loading}>
            <TemplatesContainer>
                <div style={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    marginBottom: '10px'
                }}> 
                    <Typography.Text>
                        <strong>Template Options</strong>
                    </Typography.Text>
                </div>
                <StyledAutoComplete
                    placeholder='None Selected'
                    value={templatesState.searchString || (templates?.length ? 'None Selected' : 'None Available')}
                    options={templatesState.options.map(template => ({ label: template.title, value: template.title}))} 
                    onSelect={_handleSelectTemplate}
                    onSearch={handleSearch}
                />
            </TemplatesContainer>
            <hr style={{ margin: '10px 0'}}/>
        </Spin>
    )
}

export const MemoizedTemplateSelect = memo(TemplateSelect)

