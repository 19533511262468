import React, { useState } from "react"
import ReactPanZoom from "react-image-pan-zoom-rotate";

import { 
    Modal,
    Button 
} from 'antd';

import { 
    ThumbnailContainer,
    ThumbnailImage,
 } from "../../../../Styles"

 export const Thumbnail = ({ src, alt }) => {
    const [selectedImage, selectImage] = useState(null);

    return (
        <ThumbnailContainer key={src}>
            <ThumbnailImage 
                alt={`${alt} thumbnail`} 
                src={src} 
                onClick={() => selectImage(src)}
            />
            <Modal
                visible={!!selectedImage}
                width={800}
                footer={[
                    <Button key="back" type="primary" onClick={() => selectImage(null)}>
                      Close
                    </Button>,
                  ]}
            >
                <ReactPanZoom alt="sample image" image={selectedImage} />
            </Modal>
        </ThumbnailContainer>
    )
}