//display the case allocated to physician

import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, message, Modal, Select, Switch } from "antd";
import { FilterFilled } from "@ant-design/icons";
import APIHelper from '../../axios/apiHelper';
import PhysicianAPI from '../../axios/physician/PhysicianApi'

import "./CaseSearchAndFilterComponent.css";
import CaseTagApi from "../../axios/CaseTagApi";

const { Option } = Select;
const { Search } = Input;

const CaseSearchAndFilter = (props) => {
  const [form] = Form.useForm();
  const [caseStatuses, setCaseStatuses] = useState([]);
  const [caseTags, setCaseTags] = useState([]);
  const [physicians, setPhysicians] = useState([])
  const {
    searchCase,
    showModal,
    setShowModal,
    handleCancelCross,
    handleCancel,
    handleOk,
    telemedicines,
    states,
    hideFields
  } = props;

  const handleFilter = () => {
    const data = form.getFieldValue();
    handleOk(data);
  };

  const handleClear = () => {
    form.resetFields();
    handleCancel();
  };

  
  useEffect(() => {
    APIHelper.fetchLookUp('case-status')
      .then(res => setCaseStatuses(res.data.payload))
      .catch(() => message.error('Unable to fetch list of case status filters'));

    !hideFields.includes('physician') && 
      PhysicianAPI.getPhysicians()
        .then(res => setPhysicians(res.data.physicians))
        .catch(() => message.error('Unable to fetch list of physicians'));

    CaseTagApi.getCaseTags()
        .then( res => setCaseTags(res.data.caseTags))
        .catch(() => message.error('Unable to fetch list of case tags'));

  // eslint-disable-next-line
  }, [])

  return (
    <div>
      <p className="search-filter-title">Search & Filter</p>
      <div className="search-filter-row ">
        <Search
          placeholder="Search case by name"
          size="large"
          allowClear={true}
          onSearch={(value) => searchCase(value)}
          style={{ width: 350 }}
        />
        <Button type="primary" style={{ height: "40px" }} onClick={() => setShowModal(true)}>
          <FilterFilled />
        </Button>

        <Modal
          visible={showModal}
          onOk={handleOk}
          onCancel={handleCancelCross}
          title="Filter By"
          footer={[
            <Button key="back" onClick={handleClear}>
              Clear
            </Button>,
            <Button key="submit" type="primary" onClick={handleFilter}>
              Filter
            </Button>,
          ]}
        >
          <Form layout="vertical" form={form}>
            { !hideFields?.includes('caseId') && (
              <Form.Item name="caseid" label="Case Id">
                <Input placeholder="Filter by Case Id"></Input>
              </Form.Item>
            )}

            { !hideFields?.includes('status') && (
              <Form.Item name='status' label='Case Status'>
                <Select placeholder='Filter by Case Status'>
                  {caseStatuses.map(status => <Option key={status._id} value={status.name}>{status.displayName}</Option>)}
                </Select>
              </Form.Item>
            )}

            { !hideFields?.includes('caseTags') && (
                <Form.Item name='caseTags' label='Case Tag'>
                  <Select
                      mode="tags"
                      placeholder="Case Tags">
                    { caseTags.length && caseTags.map(caseTag => <Option key={caseTag._id} >{caseTag.tag}</Option>) }
                  </Select>
                </Form.Item>
            ) }

            { !hideFields?.includes('patientId') && (
              <Form.Item name="patientid" label="Patient Id">
                <Input placeholder="Filter by Patient Id"></Input>
              </Form.Item>
            )}

            { !hideFields?.includes('telemedicineCategory') && (
              <Form.Item
                name="teleMedicineCategories"
                label="Telemedicine Category"
              >
                <Select placeholder="Filter by Telemedicine Category">
                  {telemedicines &&
                    telemedicines.map((data) => {
                      return (
                        <Option key={data._id} value={data._id}>
                          {data.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            )}

            { !hideFields?.includes('physician') && (
              <Form.Item
                name="assignedPhysician"
                label="Assigned Physician"
              >
                <Select placeholder="Filter by Assigned Physician">
                  {physicians &&
                    physicians.map((physician) => {
                      const { _id, firstName, lastName } = physician
                      return (
                        <Option key={_id} value={_id}>
                          {`${firstName} ${lastName}`}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            )}

            { !hideFields?.includes('state') && (
              <Form.Item name="state" label="State">
                <Select placeholder="Filter by state">
                  {states &&
                    states.map((data) => {
                      return (
                        <Option key={data._id} value={data._id}>
                          {data.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            )}
            
            { !hideFields?.includes('type') && (
              <Form.Item name="type" label="Consultation Type">
                <Select placeholder="Filter by Type">
                  <Option key="audio" value="audio">
                    Audio
                  </Option>
                  <Option key="video" value="video">
                    Video
                  </Option>
                  <Option key="store and forward" value="store and forward">
                    Store And Forward
                  </Option>
                </Select>
              </Form.Item>
            )}


            { !hideFields?.includes('createdDate') && (
              <Form.Item name="createddate" label="Created Date">
                <DatePicker
                  placeholder="Filter by created Date"
                  style={{ width: "100%" }}
                ></DatePicker>
              </Form.Item>
            )}

            { !hideFields?.includes('caseTags') && (
                <Form.Item name="bookmarked" label="Bookmarked Cases">
                  <Switch />
                </Form.Item>
            )}

          </Form>

        </Modal>
      </div>
    </div>
  );
};

export default CaseSearchAndFilter;
