import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from 'react-redux';
import moment from 'moment';
import debounce from 'lodash/debounce';
import { Spin, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { TablePreferencesContext } from '../../context/TablePreferencesProvider';
import { history } from "../../helpers";
import { userConstants } from "../../constants";
import { sorters } from '../../utils/sorters';
import PhysicianAPI from '../../axios/physician/PhysicianApi'

import CaseSearchAndFilterComponent from "../../components/CaseSearchAndFilterComponent/CaseSearchAndFilterComponent";

const caseFiltersKeyMap = {
    teleMedicineCategories: 'telemedicine-category-id',
    state: 'state-id',
    caseid: 'case-id',
    patientid: 'patient-id',
    type: 'type',
    createddate: 'created-date',
    status: 'status',
    caseTags: 'caseTags',
    bookmarked: 'bookmarked'
}

const AllCases = (props) => {
  const {
    title,
    type
  } = props;
  const [cases, setCases] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [caseListFilter, setCaseListFilter] = useState({});
  const { getTablePreferences, updateTablePreferences } = useContext(TablePreferencesContext);
  const { currentPage, tablePageSize, dateSortOrder } = getTablePreferences(type, { currentPage: 1, tablePageSize: 100, dateSortOrder: 'descend' });
  const { statesList, telemedicineCategoriesList } = useSelector(state => state.entities)

  const debouncedFetchCases = useMemo(() => debounce(() => {
    setIsFetching(true);
    
    const params = Object.entries(caseListFilter).reduce((params, [key, value]) => {
        const queryParam = caseFiltersKeyMap[key];
        if (queryParam) {
            if (key === 'createddate') {
              params[queryParam] = moment(value).format("YYYY-MM-DD");;
            } else if (key === 'bookmarked' && value === false) {
                delete params[queryParam];
            }
            else {
              params[queryParam] = value;
            }
        }

        return params;
    }, {})

    if (searchValue) {
      params['search-term'] = searchValue;
    }

    PhysicianAPI
      .getCaseList(params)
      .then(res => {
        setCases(res.data.payload.data);
        setTotalCount(res.data.payload.totalCount);
      })
      .finally(() => setIsFetching(false));
  }, 500), [searchValue, caseListFilter]) //wait in ms 

  const columns = [
    {
      title: "Case No",
      dataIndex: "caseId",
      // key: `${type}-caseno`,
      sorter: (a, b) => sorters.alphanumeric(a.caseId, b.caseId)
    },
    {
      title: "Patient Name",
      dataIndex: "patientName",
      // key: `${type}-name`,
      sorter: (a, b) => sorters.alphanumeric(a.patientName, b.patientName)
    },
    {
      title: "Category Name",
      dataIndex: "category",
      // key: `${type}-categoryname`,
      sorter: (a, b) => sorters.alphanumeric(a.categoryName, b.categoryName)
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      // key: `${type}-createddate`,
      sorter: (a, b) => sorters.dates(new Date(a.createddate), new Date(b.createddate))
    },
    {
      title: "Completed Date",
      dataIndex: 'completedAt',
      // key: `${type}-completedAt`,
      defaultSortOrder: dateSortOrder,
      sorter: (a, b) => sorters.dates(new Date(a.completedAt), new Date(b.completedAt))
    },
    {
      title: "Status",
      dataIndex: "statusDisplayName",
      // key: `${type}-status`,
      sorter: (a, b) => sorters.alphanumeric(a.status, b.status)
    },
  ];

  const handleIntialTableCount = () => {
    updateTablePreferences(type, { currentPage: 1, tablePageSize: 100 })
  };

  const handleOk = (filterData) => {
    setCaseListFilter(filterData);
    setShowModal(false);
    handleIntialTableCount();
  };

  const searchCase = (value) => {
    setSearchValue(value);
    handleIntialTableCount();
  };

  const handleCancel = () => {
    setCaseListFilter({});
    setShowModal(false);
    handleIntialTableCount();
  };

  const handleCancelCross = () => {
    setShowModal(false);
  };

  const onChangePage = (page) => {
    updateTablePreferences(type, { currentPage: page })
  };

  const onPageSizeChange = (_, size) => {
    updateTablePreferences(type, { currentPage: 1, tablePageSize: size })
  };

  useEffect(() => {
    debouncedFetchCases();

    return () => debouncedFetchCases.cancel();
  }, [debouncedFetchCases])

  return (
    <div className="consulted-case">
      <div className="waiting-room-case-list-part-heading">
        <h1>{title}</h1>
        <CaseSearchAndFilterComponent
          searchCase={searchCase}
          showModal={showModal}
          setShowModal={setShowModal}
          handleCancelCross={handleCancelCross}
          handleCancel={handleCancel}
          handleOk={handleOk}
          telemedicines={telemedicineCategoriesList}
          states={statesList}
          hideFields={['physician']}
        />
      </div>

      <div className="consulted-case-no-of-patient-list-data">
        <Spin
          size="large"
          spinning={isFetching}
          indicator={<LoadingOutlined spin />}
        >
          <div>
            <Table
              columns={columns}
              dataSource={cases}
              onChange={(pagination, filters, sorter) => { updateTablePreferences(type, { dateSortOrder: sorter.order || 'ascend' }) }}
              pagination={{
                total: totalCount,
                showTotal: () => `Total ${totalCount} cases`,
                current: currentPage,
                pageSize: tablePageSize,
                pageSizeOptions: ["5", "10", "20", "50", "100"],
                showSizeChanger: true,
                onChange: onChangePage,
                onShowSizeChange: onPageSizeChange,
              }}
              scroll={{ x: 900 }}
              size="large"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    history.push(
                      `/dashboard/${userConstants.USER_PHYSICIAN}/caseview/${record.caseId}`,
                      { from: title }
                    );
                  },
                };
              }}
            />
          </div>
        </Spin>
      </div>
    </div>
  );
};
export default AllCases;
